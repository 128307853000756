import { gql } from '@/utils/graphql';

export const cardCalendarFragment = gql`
	fragment cardCalendarFragment on EventDate {
		state
		date
		location {
			name
		}
		uri
		uriLabel
		isFree
		availabilityDate
		availabilityLevel
		event {
			id: databaseId
			uptitle
			title(format: RENDERED)
			startDate
			endDate
			event_custom_fields {
				specialMention
				isEvent
				isAroundTheShow
				hasDetailPage
				ticketLink
				ticketButtonText
				duration
				publics: publicCardCalendar {
					id: databaseId
					title: name
					icon: public_custom_fields {
						img: icon {
							src: relativeUrl
							mediaDetails {
								height
								width
							}
							altText
						}
					}
				}
				# production #TODO :: Add this when Production is fixed
			}
			link
			image: featuredImage {
				node {
					altText
					relativeUrl
					mediaDetails {
						width
						height
					}
				}
			}
			excerpt(format: RENDERED)
			genres {
				nodes {
					translation(language: $language) {
						id: slug
						name
					}
				}
			}
			thematics {
				nodes {
					translation(language: $language) {
						id: slug
						name
						description
						acf {
							link
						}
					}
				}
			}
			seasons {
				nodes {
					translation(language: $language) {
						id: slug
						name
					}
				}
			}
		}
	}
`;
