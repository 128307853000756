import { getWpGraphqlUrl } from '@/utils/node-utils';

// // Debug performances
// import PerfsTester from '@superhuit/starterpack-perfs-tester';

const GRAPHQL_URL = getWpGraphqlUrl();

function getQueryAttrs(query) {
	const [match, type, name] = query.match(
		/((?:query)|(?:mutation)) ([^\(\{\s]+)/
	);
	return {
		type,
		name,
	};
}

// // Debug performances
// export const fetchAPITester = PerfsTester();

export default async function fetchAPI(
	query: any,
	{ variables, auth }: any = {}
) {
	const headers = { 'Content-Type': 'application/json' };

	if (auth?.authToken) {
		headers['Authorization'] = `Bearer ${auth?.authToken}`;
	}

	let result: any = {};

	// // Debug performances
	// const match = query.match(/((?:query)|(?:mutation)) ([^\(\{\s]+)/);
	// const perfsId = fetchAPITester.markStart(`${match[1]} - ${match[2]}`);

	try {
		const res = await fetch(GRAPHQL_URL, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				query,
				variables,
			}),
		});

		const { data, errors } = await res.json();
		if (errors) {
			const { name } = getQueryAttrs(query);
			const limit = '\n===================\n';
			const sep = '\n-------------------\n';
			const vars = JSON.stringify(variables, null, 2);
			const errs = errors
				.map((e) => `   - ${e.message} [${e.extensions.category}]`)
				.join('\n');

			throw new Error(
				`\n${limit}\nFailed to fetch API\n${sep}\n== query: ${name}\n== variables: ${vars}\n== errors: \n${errs}\n${limit}\n`
			);
		}
		result = data;
	} catch (errors) {
		(Array.isArray(errors) ? errors : [errors]).map((err) =>
			console.error(err.message)
		);
	}

	// // Debug performances
	// fetchAPITester.markEnd(perfsId);

	return result;
}
